<template>
  <!-- 4个大按钮 -->
  <div>
    <div class="list-region">
      <van-tabs
        animated
        v-model="$store.state.active"
        @change="switchClick($store.state.active)"
      >
        <van-tab
          v-for="item in functionalModule.filter(item => item.id == 1 || item.id == 2 )"
          :key="item.id"
          :title="item.name"
          :name="item.id"
        >
          <div class="tabs-a">
            <h3>{{item.name}}</h3>
            <ServiceList :list="classifyType" />
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import ServiceList from '@/components/common/serviceList/serviceList'
import { mapState } from 'vuex'
export default {
  name: 'serve-list',
  components: {
    ServiceList,
  },
  data () {
    return {
      //标签下得子类
      classifyType: [],
    }
  },
  computed: {
    ...mapState(['functionalModule'])
  },
  activated () {
    this.getMenuInfo(this.$store.state.active)
  },
  methods: {
    //切换
    switchClick (i) {
      this.getMenuInfo(i)
    },
    getMenuInfo (i) {
      this.$store.state.active = i
      if (this.$store.state.active == 1) {
        this.classifyType = this.functionalModule.filter(item => item.pid == 1 && item.appRoleId != 0);
      } else if (this.$store.state.active == 2) {
        this.classifyType = this.functionalModule.filter(item => item.pid == 2 && item.appRoleId != 0)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.icon {
  font-size: 1.2rem;
}
.list-region {
  .tabs-a,
  .tabs-b {
    margin: 1rem;
  }
}
</style>