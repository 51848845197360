<template>
  <div class="fw-list">
    <div
      class="fw-lt"
      v-for="item in list"
      :key="item.id"
      @click="JumpClick(item.url,item.name)"
    >
      <svg
        class="icon icon-return"
        aria-hidden="true"
      >
        <use :xlink:href="'#' + item.logo"></use>
      </svg>
      <span>{{item.name}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array
    }
  },
  methods: {
    JumpClick (path, menuName) {
      this.$store.dispatch('JUMP', { menuName, _this: this, path })
    }
  }
}
</script>

<style lang="less" scoped>
.fw-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  .fw-lt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 6rem;
    width: 25%;
    .icon {
      font-size: 3rem;
    }
    span {
      white-space: nowrap;
      padding-top: 0.5rem;
    }
  }
}
</style>